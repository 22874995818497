import * as React from "react"

import { Box, Button, Grid, HStack, Heading, Input, Select, SimpleGrid, Stack, Text, } from "@chakra-ui/react"
import { PageProps, graphql } from "gatsby"
import { blueFilters, breadcrumbData, genderFilter } from "../components/Shop/_data"
import { useEffect, useState } from 'react';

import { CheckboxFilter } from "../components/Shop/CheckboxFilter"
import Layout from "../components/Base/Layout"
import { MobileFilter } from "../components/Shop/MobileFilter"
import { PriceRangePicker } from "../components/Shop/PriceRangePicker"
import { SEO } from "../components/Base/Seo";
import { SingleProductCard } from "../components/Product/SingleProductCard"

// import InfiniteScroll from "react-infinite-scroll-component"

const Shop: React.FC<Shop> = ({ data }) => {

  const {
    // data:{
      allWpProduct: {
            nodes
          },
    // }
   
  } = data
  console.log(data.allWpProduct.nodes)

  // *SORT
  // initial array of all products
  const [products, setProducts] = useState(() => {
    return [...nodes]
  });

  function onSelectionChange(e: any) {
    // check which select value is chosen
    const sortDirection = e.target.value 

    // making a shallow copy of an initial array of products
    const copyArray = [...nodes]; 

    //sorting copy array based on which value is chosen
    nodes.sort((a:any, b:any) => {
 
      if (sortDirection == 'a-z') {
        return a.name.localeCompare(b.name)
      }

      if (sortDirection == "z-a") {
        return b.name.localeCompare(a.name)
      }

      if (sortDirection == 'priceDESC') {
      
        const aPrice = Number(a.price.replace(/[^0-9.-]+/g,""));
        const bPrice = Number(b.price.replace(/[^0-9.-]+/g,""));

        return aPrice - bPrice
      }

      if (sortDirection == 'priceASC') {
        const aPrice = Number(a.price.replace(/[^0-9.-]+/g,""));
        const bPrice = Number(b.price.replace(/[^0-9.-]+/g, ""));

        return bPrice - aPrice
      }

    });

    // re-render products
    setProducts(copyArray)

  }

  // !PRICE SLIDER 

  const [filteredP, setFilteredP] = useState(nodes);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
    const [error, setError] = useState(false);
  // price filter
  const PriceFilter = (e: any) => {

    if ((minPrice > maxPrice) || (maxPrice < minPrice)) {
      setError(true)
      setFilteredP(nodes)
    } else if (minPrice == 0 && maxPrice == 0 ) {
      setFilteredP(nodes)
      setError(false)
    } else {
      let list = nodes.filter(
        (item) => Number(item.price.replace(/[^0-9.-]+/g, "")) >= minPrice && Number(item.price.replace(/[^0-9.-]+/g, "")) <= maxPrice
    )
    setFilteredP(list)
    setError(false)
    }
  }


  const [filters, setFilters] = useState({
    categories: []
  })

  const showFilterResults = (filters:any) => {
    let updatedList = nodes;

    filters.includes("dGVybToyMzM=")
    console.log(filters.includes("dGVybToyMzM="))
    
    // if (filters.length) {
    updatedList = nodes.filter((i: any) => filters.includes(i.productCategories.cat.map((j: any) => j.id == "dGVybToyMzM=")))
    let testList = nodes.filter((i:any) => i.productCategories.cat.map((j: any) => j.id == "dGVybToyMzM="))
    // }

    console.log(updatedList)
    console.log(testList)
    
  }

  const handleFilters = (filters:any, categories:any) => {

    const newFilters = { ...filters }
    newFilters[categories] = filters
    console.log(newFilters[categories])

    setFilters(newFilters)
    showFilterResults(newFilters[categories])
    
  }

  const ProductDisplay = () => { 
      
    if (filteredP.length) {

      return <>{
        filteredP.map((item) => (
          <div key={item.id}>
            <SingleProductCard props={item}></SingleProductCard>
          </div>
        ))
      }</>
    } else if (filteredP.length == 0) {
      return <>{
        <Text>No products were found matching your selection.</Text>
      }</>
    }

  }

  return (
    <Layout>
        <Box width="100%" bg="white" mx="auto" px={{ base: '4', md: '8', lg: '12' }} py={{ base: '6', md: '8', lg: '12' }}>
            {/* <ProductBreadcrumb data={breadcrumbData} /> */}
                <Box mt={{ base: '8', md: '16' }}>
                    <Grid templateColumns={{ base: '1fr', lg: '240px 1fr' }} gap="14">
                        <Stack spacing="10" maxW="240px" display={{ base: 'none', lg: 'flex' }}>
                        <CheckboxFilter spacing="3" options={genderFilter.options} label="Category" handleFilters={(filters:any) => handleFilters(filters, "category")} />
                        {/* <CheckboxFilter spacing="3" options={blueFilters.options} label="Brand" showSearch /> */}
                        <Stack spacing="5">
                
                            <label>Price range</label>
                            {/* <PriceRangePicker defaultValue={[6, 40]} value={selectedPrice}/> */}
                
                           {/* PRICE RANGE INPUT FIELDS */}
                          <form>
                          <HStack spacing="6">
                          
                              <Input name="minPrice" defaultValue={minPrice} onChange={event => setMinPrice(event.target.value)} type="number" placeholder="$500" />
                              <Input name="maxPrice" defaultValue={maxPrice} onChange={event => setMaxPrice(event.target.value)} type="number" placeholder="$1,000" />
                          
                            </HStack>
                            {error ? <Text color="red">Wrong input!</Text> : <></>}
                            <Button mt='24px' type="button" onClick={PriceFilter} w="100%">Apply price filter</Button>
                            </form>
                        </Stack>
                        </Stack>

                        <Box width="full">
                        <Stack spacing={{ base: '6', lg: '4' }} direction={{ base: 'column', lg: 'row' }} justify="space-between" align="flex-start" width="full" >
                            <Stack direction={{ base: 'column', lg: 'row' }} align="baseline">
                            <Heading size="md" fontSize="2xl">
                                All Products
                            </Heading>
                              <Text color="gray.500">  ({ filteredP.length } products) </Text>
                            </Stack>
                            <MobileFilter />
                            <HStack display={{ base: 'none', lg: 'flex' }}>
                            <Text flexShrink={0} color="gray.500" fontSize="sm">
                                Sort by
                          </Text>
                  
                            {/* <SortbySelect /> */}
                            <Select size="sm" aria-label="Sort by" rounded="md" onChange={onSelectionChange}>
                              <option value="a-z">Name: A-Z</option>
                              <option value="z-a">Name: Z-A</option>
                              <option value="priceASC">Price: DESC</option>
                              <option value="priceDESC">Price: ASC</option>
                            </Select>
                          
                            </HStack>
                          </Stack>
                           
                          <Box mt="6" minH="480px" rounded="xl">
                            <SimpleGrid columns={{base: 1, md:2, xl:3}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
                     
                              {/* DISPLAY PRODUCTS */}

                              {/* {nodes.map(i => i.productCategories.cat.map(j => j.name))} */}
                              {ProductDisplay()}

                            </SimpleGrid>
                            
                          </Box>
                        </Box>
                    </Grid>
            </Box>
      </Box>
    </Layout>
  )
}

type Shop = PageProps<ShopProps>

type ShopProps = {
  // data: {
    allWpProduct: {
        totalCount: number,
        nodes: Array<{ name: string; id: string; slug: string; price: string; databaseId: number; image: { sourceUrl?: string }, productCategories: {cat: [{name: string, id: string}]} }>
        pageInfo: { currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean, itemCount: number, perPage: number, totalCount: number, pageCount: number },
    },
  // }
}

export const query = graphql`
  query Shop {
    allWpProduct {
    totalCount
    nodes {
      name
      id
      slug
      databaseId
      productTags {
        nodes {
          name
        }
      }
      productCategories {
        cat: nodes {
          name
          id
          slug
        }
      }
      image {
        sourceUrl
      }
      ... on WpSimpleProduct {
        id
        name
        price
      }
    }
     pageInfo {
      currentPage
      hasNextPage
      itemCount
      totalCount
      perPage
      hasPreviousPage
      pageCount
    }
  }
  }
`

export default Shop


export const Head = () => (
  <SEO />
)